<template>
  <label :class="`credits-suugest${isSelected ? ' selected' : ''}`">
    <div class="credits-suugest-left">
      <div class="radio">
        <input class="mycheck circle" type="radio" name="reloadSuggest" noevents @change="isChecked($event)" />
      </div>
      <div class="icon">
        <div class="iconWrap"><iconic name="coins" /></div>
      </div>
      <div class="priceDesc num">
        <div class="num-prices" :set="(hasPlus = item.obtainPlus > item.obtain)">
          <table class="tablePriceDesc" width="100%">
            <tr>
              <td>{{ $locale["credits"] }}</td>
              <td tcenter>{{ $locale["extra"] }}</td>
              <td tright>{{ $locale["total"] }}</td>
            </tr>
            <tr>
              <td>
                <span class="number __regular">{{ Go.number_format(item.obtain) }}</span>
              </td>
              <td tcenter>
                <span class="number __extra">{{ Go.number_format(item.obtainPlus - item.obtain) }}</span>
              </td>
              <td tright>
                <span class="number __plus">{{ Go.number_format(item.obtainPlus) }}</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="totalUSDBlock">
          <div class="checkout">
            <PrimaryButton v-if="isSelected && !isConfirm" :label="$locale['reload_credits']" @click="payConfirm" />
            <div class="infoLabel" v-else>
              <span class="label">{{ $locale["buy_credits"] }}</span>
            </div>
          </div>
          <div class="price">
            <span class="num" tright>
              <span block class="numberUSD totalUSDMount">$ {{ $global.number_format(item.price, 2) }}</span>
              <span block class="currency">USD</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  props: ["item", "isConfirm"],
  methods: {
    isChecked: function(event) {
      const checked = event.target.checked;
      if (checked) {
        this.$store.state.credits.itemToReload = this.item;
        this.$emit("checked", this.item);
      }
    },
    getDiscountPercent: function(regularObtain, plusObtain) {
      const percent = ((regularObtain - plusObtain) / plusObtain) * 100;
      return percent.toFixed(0);
    },
    payConfirm: function() {
      this.view({
        title: this.$locale["buy_credits"] || "",
        component: () => import("@/components/credits/CreditsReloadConfirm.vue"),
        animation: "midTopIn",
        item: this.item,
        class: "menu",
        isConfirm: true,
      });
    },
  },
  computed: {
    isSelected: function() {
      this.itemToReload = this.$store.state.credits.itemToReload._id === this.item._id;
      return this.itemToReload;
    },
  },
};
</script>

<style lang="scss">
.credits-suugest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white_smoke;
  border: solid 1px $white_smoke;
  border-radius: $mpadding;
  padding: $mpadding;
  transition: all 0.3s ease;
  cursor: pointer;
  user-select: none;
  &:active {
    @include d-active();
  }

  &.selected {
    border: solid 1px $primary_color;
  }

  &.selected .radio {
    font-size: 120%;
  }

  &.selected .mycheck:checked {
    background-color: $primary_color;
    border-color: $primary_color;
  }

  &.selected .icon {
    color: $primary_color;
  }

  .iconWrap {
    padding: 0 $mpadding/2 0 0;
  }

  .iconic {
    @include Flex(inherit, center, center);
  }

  &-left,
  &-right {
    display: flex;
    align-items: center;
  }

  &-left {
    flex: 1;
    position: relative;
    .radio {
      display: flex;
      align-items: center;
      margin: 0 $mpadding 0 0;
      display: none;
      .mycheck {
        width: 1em;
        height: 1em;
      }
    }
    .icon {
      margin: 0 $mpadding/2 0 0;
      font-size: 250%;
    }
    .number {
      font-weight: bold;
      font-size: 120%;
      &.__regular {
        text-decoration: none;
      }
      &.__extra {
        font-size: 120%;
      }
      &.__plus {
        color: $primary_color;
        font-family: $third_font_bold;
      }
    }
  }

  .tablePriceDesc tr td,
  .tablePriceDesc tr th {
    padding: 0 $mpadding/2;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  .totalUSDBlock {
    padding: $mpadding/1.5 0 0 0;
    border-top: solid 1px $alto;
    margin: $mpadding/2 0 0 0;
    display: flex;
    justify-content: space-between;
  }

  .checkout {
    flex: 1;
  }

  .checkout .infoLabel {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: $mpadding;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.3em 0 0.3em 0.3em;
      border-color: transparent transparent transparent $primary_color;
      z-index: 1;
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: $mpadding;
      left: 0;
      border-bottom: solid 1px $alto;
    }
    .label {
      background-color: #fff;
      position: relative;
      z-index: 2;
      display: inline-block;
      padding: $mpadding/2 $mpadding;
      border: solid 1px $alto;
      border-radius: 2pc;
    }
  }

  .priceDesc {
    flex: 1;
  }

  .totalUSDMount {
    font-family: $third_font_bold;
    font-size: 120%;
  }

  .infoLabel {
    opacity: 0.7;
  }
}
</style>
